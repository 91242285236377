import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import MatchItem from '../MatchItem/MatchItem';
import ChangeMatchItem from '../ChangeMatchItem/ChangeMatchItem';
import InactiveMatchItem from '../InactiveMatchItem/InactiveMatchItem';
import request from '../../../../helpers/request';
import { FaRegSadTear } from "react-icons/fa";
import './OfficialScoresPanel.css';

const OfficialScoresPanel = (props) => {
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();

    const { matches, logout, getMatches, handleOpenModal, handleOpenErrorModal } = props;
    const [scoreValues, setScoreValues] = useState({});
    const [yellowCards, setYellowCards] = useState({});
    const [redCards, setRedCards] = useState({});
    const [penalty, setPenalty] = useState({});



    const handleScoreChange = (matchId, team, value) => {
        setScoreValues(prevState => ({
            ...prevState,
            [matchId]: {
                ...prevState[matchId],
                [team]: value
            }
        }));
    };

    const handleYellowCardsChange = (matchId, team, value) => {
        setYellowCards(prevState => ({
            ...prevState,
            [matchId]: {
                ...prevState[matchId],
                [team]: value
            }
        }));
    };

    const handleRedCardsChange = (matchId, team, value) => {
        setRedCards(prevState => ({
            ...prevState,
            [matchId]: {
                ...prevState[matchId],
                [team]: value
            }
        }));
    };
    const handlePenaltyChange = (matchId, team, value) => {
        setPenalty(prevState => ({
            ...prevState,
            [matchId]: {
                ...prevState[matchId],
                [team]: value
            }
        }));
    };

const updateResult = (type,match_id, result_team_1, result_team_2, penaltyTeam1, penaltyTeam2) => {
    request.post(`/matches/update-result/${user_id}`, {
        type,
        match_id,
        result_team_1,
        result_team_2,
        penaltyTeam1, 
        penaltyTeam2
    },{
        headers: {
            'Authorization': token
        }})
    .then((res => {
        handleOpenModal("Pomyślnie zaktualizowno wynik.");
        getMatches(new Date());
    }))
    .catch(e => {
        const { status, message } = e.response.data;
        if (status === 400) {
          handleOpenErrorModal(message)
          getMatches(new Date());
        } else if (status === 401){
            logout();
        }
      });
}

    const matchesList = matches ?  matches.map((match) => { 
        const matchId = match._id;
        const resultTeam1 = scoreValues[matchId]?.resultTeam1 ?? (match.team_1.score ?? "");
        const resultTeam2 = scoreValues[matchId]?.resultTeam2 ?? (match.team_2.score ?? "");

        // const yellowCardsTeam1 = yellowCards[matchId]?.yellowCardsTeam1 ?? (match.team_1.yellow_cards ?? "");
        // const yellowCardsTeam2 = yellowCards[matchId]?.yellowCardsTeam2 ?? (match.team_2.yellow_cards ?? "");

        // const redCardsTeam1 = redCards[matchId]?.redCardsTeam1 ?? (match.team_1.red_cards ?? "");
        // const redCardsTeam2 = redCards[matchId]?.redCardsTeam2 ?? (match.team_2.red_cards ?? "");

        const penaltyTeam1 = penalty[matchId]?.penaltyTeam1 ?? (match.team_1.penalty ?? "");
        const penaltyTeam2  = penalty[matchId]?.penaltyTeam2 ?? (match.team_2.penalty ?? "");
        return (

            match.isEditable  ? (
                match.added ? (
                    <ChangeMatchItem 
                    key={match._id} 
                    match={match} 
                    resultTeam1={resultTeam1} 
                    resultTeam2={resultTeam2} 
                    matchId={matchId} 
                    handleScoreChange={handleScoreChange} 
                    penaltyTeam1={penaltyTeam1}
                    penaltyTeam2={penaltyTeam2}
                    handlePenaltyChange={handlePenaltyChange}
                    action={updateResult} />
                ) : (
                    <MatchItem 
                        key={match._id} 
                        match={match} 
                        resultTeam1={resultTeam1} 
                        resultTeam2={resultTeam2} 
                        matchId={matchId} 
                        penaltyTeam1={penaltyTeam1}
                        penaltyTeam2={penaltyTeam2}
                        handleScoreChange={handleScoreChange} 
                        handlePenaltyChange={handlePenaltyChange}
                        action={updateResult} />
                )
            ) : (
                    <InactiveMatchItem key={match._id}  match={match} resultTeam1={resultTeam1} resultTeam2={resultTeam2}/>

            )
        );
    })
    : null;


    return (
        matchesList.length > 0  ? matchesList : <p className='lack-of-matches'><FaRegSadTear className='sad-icon'/> <span>Brak meczów</span></p>
    );
};

export default OfficialScoresPanel;
