import React from 'react';

import './CustomCreateButton.css';

const CustomCreateButton = ({ action, text, isDisabled }) => {
    return (
        isDisabled ?
            (
                <span className="custom-create-button">
                    {text}
                </span>
            )
            : 
            (
                <span className="custom-create-button-active" onClick={action}>
                    {text}
                </span>
            )
            
        

    );
};

export default CustomCreateButton;