import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import request from '../../helpers/request';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import RoomInfoPanel from './components/RoomInfoPanel/RoomInfoPanel';
import IncreaseRoomPanel from './components/IncreaseRoomPanel/IncreaseRoomPanel';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; 

import SimpleModal from '../../components/SimpleModal/SimpleModal';

import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import ImageQuestion from '../../icons/question.png';

import DecisionModal from '../../components/DecisionModal/DecisionModal';

import './RoomSettingsPage.css';

const RoomSettingsPage = () => {
    const { id } = useParams();
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;
    const navigate = useNavigate();
    const [room, setRoom] = useState({});
    const [loading, setLoading] = useState(true); 


    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});
    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});
  
    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});
  
    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});


    const handleOpenDecisionModal = (text, action) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> action())

    }

    const handleCloseDescisionModal = () => setShowModalDescison({status: false});
    
    const getRoomInfo = () => {    
        request.post(`/rooms/info-for-admin/${user_id}`,
            { roomName: id },
            { headers: { 'Authorization': token } }
        )
        .then(res => {
            setRoom(res.data.room)
            setLoading(false)
        })
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400){
                navigate('/user/rooms');
            } else if (status === 401){
                navigate('/user/rooms');
            }
        });
    };

    const enlargeRoom = (players) => {
        if (token) {
          if (request.post(`/rooms/enlarge/${user_id}`, {
              players,
              roomName: id

            },{
                headers: {
                    'Authorization': token
                }})
            .then((res) => {
                handleOpenModal("Pomyślnie powiększono pokój.");
                getRoomInfo();
                return true
            })
            .catch((e) => {
              const { status, message } = e.response.data;
              if (status === 400) {
                handleOpenErrorModal(message);
                return false
              } else if (status === 401){
                navigate('/user/rooms');
            }
            })) return true
            else return false
          }
    
      };

    const deactivateRoom = () => {
        if (token) {
         request.post(`/rooms/deactivate/${user_id}`, {
              roomName: id
            },{
                headers: {
                    'Authorization': token
                }})
            .then((res) => {
                handleOpenModal("Pomyślnie dezaktywowano pokój.");
                getRoomInfo();
            })
            .catch((e) => {
              const { status, message } = e.response.data;
              if (status === 400) {
                handleOpenErrorModal(message);
              } else if (status === 401){
                navigate('/user/rooms');
            }
            });
          }
    
      };

    const activateRoom = () => {
        if (token) {
         request.post(`/rooms/activate/${user_id}`, {
              roomName: id
            },{
                headers: {
                    'Authorization': token
                }})
            .then((res) => {
                handleOpenModal("Pomyślnie aktywowano pokój.");
                getRoomInfo();
            })
            .catch((e) => {
              const { status, message } = e.response.data;
              if (status === 400) {
                handleOpenErrorModal(message);
              } else if (status === 401){
                navigate('/user/rooms');
            }
            });
          }
    
      };



    useEffect(()=>{
        getRoomInfo();
    },[])
    return (
        <UITemplate title="Ustawienia">
            {loading ? (
                <LoadingPanel />
            ) : (
                <>
                    <RoomInfoPanel room={room}/>
                    <IncreaseRoomPanel enlargeRoom={enlargeRoom}/>
                    {room.status ? 
                    <div onClick={()=> handleOpenDecisionModal("Czy napewno chcesz dezaktywować pokój?",deactivateRoom)} className='deactivate-button'>Dezaktywuj</div>
                    :
                    <div onClick={()=> handleOpenDecisionModal("Czy napewno chcesz aktywować pokój?", activateRoom)} className='activate-button'>Aktywuj</div>
                    }
                </>
            )}

            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>

            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>

            <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                <img className="icon-modal" src={ImageAccept} alt="" />
            </SimpleModal>
        </UITemplate>
    );
};

export default RoomSettingsPage;
