import React, { useContext, useState } from 'react';
import Cookies from 'js-cookie';
import request from '../../helpers/request';
import LoginPanel from './components/LoginPanel/LoginPanel';
import { AuthContext } from '../../helpers/AuthContext';
import { IoArrowBackOutline } from "react-icons/io5";
import './LoginPage.css';

import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import { IoIosFootball } from "react-icons/io";

const LoginPage = () => {
    const { setIsLoggedIn } = useContext(AuthContext);
    const [loading, setLoading] = useState(false); 
    const [showModalError, setShowModalError] = useState({status: false, message: ""})
    
    const handleOpenErrorModal = (text) => {
        setShowModalError({status: true, message: text})
      }
      
     const handleCloseErrorModal = () => {
        setShowModalError({status: false})
      }

    const setTokenInCookie = (token) => {
        Cookies.set('access_token', token, { expires: 30 });
    };


    const sendLoginRequest = (e, loginForm) => {
        e.preventDefault();
        setLoading(true); 
        request.post('/auth/admin-login', loginForm)
            .then(res => {
                const token = res.data.access_token;
                if (token) {
                    setTokenInCookie(token);
                    setIsLoggedIn(true);
                }
            })
            .catch(e => {
                const { code, message } = e.response.data;
                if (code === 400) {
                    handleOpenErrorModal(message);
                }
            })
            .finally(() => {
                setLoading(false); 
            });
    };


    return (
        <section className='login-page'>
            <div className='banner'>
                <IoIosFootball/>
                <span>TYPER</span>

            </div>
            <LoginPanel 
                loading={loading}
                sendLoginRequest={sendLoginRequest}
            />
            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>

        </section>
    );
};

export default LoginPage;
