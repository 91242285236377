import React, { useState } from 'react';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import './IncreaseRoomPanel.css';
import { HiMiniPlusCircle, HiMinusCircle } from "react-icons/hi2"; 
import { IoMdPersonAdd } from "react-icons/io";
import request from '../../../../helpers/request';
import { useNavigate, useParams } from 'react-router-dom';


const IncreaseRoomPanel = ({enlargeRoom}) => {
    const { id } = useParams(); 
    const [isActive, setIsActive] = useState(false);
    const [players, setPlayers] = useState(1);

 
    const handlePlayers = (type) => {
        if (type === "minus" && players > 1) {
            setPlayers(players - 1);
        } else if (type === "plus") {
            setPlayers(players + 1);
        } 
    }

    const handleOnSubmit = () => {
      if (enlargeRoom(players)) {
          setPlayers(1);
          setIsActive(false)
      } 
  }




    const handleOnClick = (e) => setIsActive(!isActive);

    return (
        <div className={`increase-room-panel ${isActive ? 'active' : ''}`}>
            <div className='increase-room-button' onClick={handleOnClick}>Powiększ pokój</div>
            {isActive && ( 
                <>
                    <span className='title'>Wybierz liczbe graczy</span>
                    <div className='players-panel'>
                        <HiMinusCircle className='icon-action' onClick={() => handlePlayers("minus")} />
                        <span className='players'>
                            {players}
                        </span>
                        <HiMiniPlusCircle className='icon-action' onClick={() => handlePlayers("plus")} />
                    </div>
                    <div className='pay-room-players-button' onClick={handleOnSubmit}><IoMdPersonAdd className='add-icon'/> Dodaj</div>
                </>
)}
        </div>
    );
};

export default IncreaseRoomPanel;