import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthContext } from '../../helpers/AuthContext.js';
import { BsDoorClosed, BsArrowBarRight, BsPerson, BsBox } from "react-icons/bs";
import { TbScoreboard } from "react-icons/tb";


import './UserNavigation.css';

import ImageQuestion from '../../icons/question.png';

import DecisionModal from '../../components/DecisionModal/DecisionModal.js';

const UserNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState('');
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});


    const handleOpenDecisionModal = (text) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> logout())

    }

    const handleCloseDescisionModal = () => setShowModalDescison({status: false});

    const logout = () => {
        Cookies.remove('access_token');
        setIsLoggedIn(false);
        navigate('/');
    };


    useEffect(() => {
            setCurrentPath(location.pathname);
    }, [location.pathname]);

    return (
        isLoggedIn ? (
            <div className='user-navigation'>

                <span className={`${currentPath === '/user/rooms' ? ' active' : ''}`}><Link to='/user/rooms'><BsDoorClosed className={`icon-nav`} /></Link></span>
                <span className={`${currentPath.includes('accounts') ? ' active' : ''}`}><Link to='/user/accounts'><BsPerson  className={`icon-nav`} /></Link></span>
                <span className={`${currentPath.includes('scores') ? ' active' : ''}`}><Link to={`/user/scores`}><TbScoreboard className={`icon-nav`} /></Link></span>
                <span className={`${currentPath.includes('orders') ? ' active' : ''}`}><Link to='/user/orders'><BsBox  className={`icon-nav`} /></Link></span>
                <span><BsArrowBarRight className="icon-nav logout" onClick={()=> handleOpenDecisionModal("Czy napewno chcesz się wylogować?")}/></span>

            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>
            </div>
        ) : null
    );
};

export default UserNavigation;
