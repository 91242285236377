import React, { useEffect } from 'react';
import './CustomInput.css';
import { HiMiniPlusCircle, HiMinusCircle } from "react-icons/hi2"; 

const CustomInput = (props) => {

    const {handlePlayers, players} = props;

    return (
        <label className='custom-input'>
            <HiMinusCircle className='icon-action' onClick={()=> handlePlayers("minus")}/>
            <span className='players'>
                {players}
            </span>
            <HiMiniPlusCircle className='icon-action' onClick={()=> handlePlayers("plus")}/>
            <span>graczy</span>
        </label>
    );
};

export default CustomInput;