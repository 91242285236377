import React, { useState } from 'react';
import './CreateAccountPage.css';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import CreateAccountPanel from './components/CreateAccountPanel/CreateAccountPanel';
import ImageError from '../../icons/lock.png';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import request from '../../helpers/request';
import { useNavigate } from 'react-router-dom';
import SimpleModal from '../../components/SimpleModal/SimpleModal';

const CreateAccountPage = () => {
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;

    const [showModalError, setShowModalError] = useState({status: false, message: ""})
    const navigate = useNavigate();
    const handleOpenErrorModal = (text) => {
        setShowModalError({status: true, message: text})
      }
      
     const handleCloseErrorModal = () => {
        setShowModalError({status: false})
      }
    const sendCreateAccountRequest = (e, createAccountForm) => {
        e.preventDefault();
        request.post(`/auth/create-admin-account/${user_id}`, createAccountForm, {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                navigate('/user/accounts');
            })
            .catch(e => {
                const { code, message } = e.response.data;
                if (code === 400) {
                    handleOpenErrorModal(message);
                } else if (code === 401){
                    navigate('/user/rooms')
                }
            })
    };


    return (
        <UITemplate title={"Utwórz konto"}>
            <CreateAccountPanel sendCreateAccountRequest={sendCreateAccountRequest}/>

            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>
        </UITemplate>
    );
};

export default CreateAccountPage;
