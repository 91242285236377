import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './RoomInfoPanel.css'


const RoomInfoPanel = ({room}) => {
    return (
        <div className='room-info-panel'>
            <p>Pokój {room.roomName}</p>
            <span className='number-of-players'>Liczba graczy w pokoju: <span>{room.playersCount} / {room.size}</span></span>
        </div> 
    );
};

export default RoomInfoPanel;