import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateRoomPage from '../../pages/CreateRoomPage/CreateRoomPage.js';
import RoomsListPage from '../../pages/RoomsListPage/RoomsListPage.js';
import RoomSettingsPage from '../../pages/RoomSettingsPage/RoomSettingsPage.js';
import RoomOfficialScoresPage from '../../pages/RoomOfficialScoresPage/RoomOfficialScoresPage.js';
import { AuthContext } from '../../helpers/AuthContext.js';
import Cookies from 'js-cookie';
import OrdersPage from '../../pages/OrdersPage/OrdersPage.js';
import AccountsPage from '../../pages/AccountsPage/AccountsPage.js';
import CreateAccountPage from '../../pages/CreateAccountPage/CreateAccountPage.js';

const UserContent = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    Cookies.remove('access_token');
    setIsLoggedIn(false);
    navigate('/login');
};

  useEffect(() => {
    if (isLoggedIn && localStorage.getItem('targetPath')) {
        const targetPath = localStorage.getItem('targetPath');
        localStorage.removeItem('targetPath');
        navigate(targetPath);
    }
  
    if (isLoggedIn && location.pathname === '/login') {
        navigate('/user/rooms');
    }
  
  }, [isLoggedIn, location.pathname]);

  const SecuredRoomPage = () => {
    return (
      <Routes>
        <Route path='/' element={<RoomSettingsPage />} />
      </Routes>
    );
  };

  return (
    <Routes>
      <Route path='/orders' element={<OrdersPage/>} />
      <Route path='/scores' element={<RoomOfficialScoresPage />} />
      <Route path='/accounts' element={<AccountsPage />} />
      <Route path='/create-account' element={<CreateAccountPage />} />
      <Route path='/rooms' element={<RoomsListPage />} /> 
      <Route path='/create-room' element={<CreateRoomPage />} />
      <Route path='/rooms/:id/*' element={<SecuredRoomPage />} />
      <Route path='*' element={ <RoomOfficialScoresPage />}/>
    </Routes>
  );
};

export default UserContent;
