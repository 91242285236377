import React, { useState } from 'react';
import './LoginPanel.css';
import LoginContent from './components/LoginContent/LoginContent';

const LoginPanel = (props) => {
    const {sendLoginRequest, loading} = props;
    return (
        <section className='login-panel'>
            <div className='container-login'>
                <LoginContent 
                    sendLoginRequest={sendLoginRequest} 
                    loading={loading}
                />
            </div>
        </section>
    );
};

export default LoginPanel;