import React, { useState } from 'react';
import CustomLoginInput from '../CustomLoginInput/CustomLoginInput';
import CustomLoginButton from '../CustomLoginButton/CustomLoginButton';
import './CreateAccountPanel.css';
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const CreateAccountPanel = (props) => {
    const { sendCreateAccountRequest} = props;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const handleOnChangeUsername = (e) => {
        setUsername(e.target.value);
    };

    const handleOnChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleOnChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!username || !password || !confirmPassword ) {
            setErrorMessage('Wszystkie pola są wymagane');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Hasła nie są identyczne');
            return;
        }

        const createAccountForm = {
            username,
            password
        };

        sendCreateAccountRequest(e, createAccountForm);
    };

    return (
        <form className='create-account-panel'>
            <CustomLoginInput handleOnChange={handleOnChangeUsername} valueInput={username} labelName={"Login"} typeInput={"text"} placeholder={"Wprowadź nazwę użytkownika"} />
            <CustomLoginInput handleOnChange={handleOnChangePassword} valueInput={password} labelName={"Hasło"} typeInput={"password"} placeholder={"Wprowadź hasło"} />
            <CustomLoginInput handleOnChange={handleOnChangeConfirmPassword} valueInput={confirmPassword} labelName={"Potwierdź hasło"} typeInput={"password"} placeholder={"Potwierdź hasło"} />
            <CustomLoginButton action={handleSubmit} text="Stwórz konto" >
               Stwórz konto
            </CustomLoginButton>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
    );
};

export default CreateAccountPanel;
