import React, { useState, useEffect, useContext } from 'react';
import './AccountsPage.css';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import { IoIosAdd } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel';
import request from '../../helpers/request';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import AccountItem from './components/AccountItem/AccountItem';
import { AuthContext } from '../../helpers/AuthContext.js';

import SimpleModal from '../../components/SimpleModal/SimpleModal';

import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import ImageQuestion from '../../icons/question.png';

import DecisionModal from '../../components/DecisionModal/DecisionModal';


const AccountsPage = () => {
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const id = decodedToken.id;
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true); 
    
    const [modalAction, setModalAction] = useState();
    const [showModalDescison, setShowModalDescison] = useState({status: false, message: ""});
    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});
  
    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});
  
    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});


    const handleOpenDecisionModal = (text, action) => {
        setShowModalDescison({status: true, message: text});
        setModalAction(()=> ()=> action())

    }

    const handleCloseDescisionModal = () => setShowModalDescison({status: false});
    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthContext);

    const logout = () => {
        Cookies.remove('access_token');
        setIsLoggedIn(false);
        navigate('/');
    };

    const getRooms = () => {    
        request.get(`/user/admin-accounts/${id}`,{
            headers: {
                'Authorization': token
            }})
        .then(res => {
            setAccounts(res.data.accounts);
            setLoading(false); 
        })
        .catch(e => {
          const { status, message } = e.response.data;
          if (status === 400) {
            handleOpenErrorModal(message);
            setLoading(false); 
          } else if (status === 401) {
            logout();
          }
        });
    };

    const deactivateAccount = (account_id) => {    
        request.post(`/user/deactivate-account/${id}`,{
            account_id
        },{
            headers: {
                'Authorization': token
            }})
        .then(res => {
            getRooms();
            handleOpenModal("Pomyślnie dezaktywowano konto.")
        })
        .catch(e => {
          const { status, message } = e.response.data;
          if (status === 400) {
            handleOpenErrorModal(message);
            setLoading(false); 
          } else if (status === 401) {
            logout();
          }
        });
    };

    const activateAccount = (account_id) => {    
        request.post(`/user/activate-account/${id}`,{
            account_id
        },{
            headers: {
                'Authorization': token
            }})
        .then(res => {
            getRooms()
            handleOpenModal("Pomyślnie aktywowano konto.")
        })
        .catch(e => {
          const { status, message } = e.response.data;
          if (status === 400) {
            handleOpenErrorModal(message);
            setLoading(false); 
          } else if (status === 401) {
            logout();
          }
        });
    };



    useEffect(() => {
        getRooms();
    }, []);

    const accountsList = accounts.length > 0 ? accounts.map((account) => <AccountItem key={account._id} id={account._id} username={account.username} role={account.role} active={account.active} submit={handleOpenDecisionModal} activateAccount={activateAccount} deactivateAccount={deactivateAccount}/> ) : null;

    return (
        <UITemplate title={"Konta"}>
            <Link className={"create-account"} to={`/user/create-account`}><IoIosAdd/></Link>
            {loading ? <LoadingPanel /> : accountsList} 

            <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
                <img className="icon-modal" src={ImageError} alt="" />
            </SimpleModal>

            <DecisionModal handleCloseModal={handleCloseDescisionModal} action={modalAction} showModal={showModalDescison}> 
                <img className="icon-modal" src={ImageQuestion} alt="" />
            </DecisionModal>

            <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
                <img className="icon-modal" src={ImageAccept} alt="" />
            </SimpleModal>
        </UITemplate>

    );
};

export default AccountsPage;