import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import request from '../../helpers/request';
import OfficialScoresPanel from './components/OfficialScoresPanel/OfficialScoresPanel';
import { useNavigate } from 'react-router-dom';
import UITemplate from '../../layouts/UITemplate/UITemplate';
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel'; 
import { AuthContext } from '../../helpers/AuthContext.js';
import './RoomOfficialScoresPage.css';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import ImageError from '../../icons/lock.png';
import ImageAccept from '../../icons/accept.png';
import DateBar from './components/DateBar/DateBar';

const RoomOfficialScoresPage = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();
    const token = Cookies.get('access_token');
    const decodedToken = jwtDecode(token);
    const user_id = decodedToken.id;

    const [showModalError, setShowModalError] = useState({status: false, message: ""});
    const [showModal, setShowModal] = useState({status: false, message: ""});

    const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
    const handleCloseErrorModal = () => setShowModalError({status: false});

    const handleOpenModal = (text) => setShowModal({status: true, message: text});  
    const handleCloseModal = () => setShowModal({status: false});

    const { setIsLoggedIn } = useContext(AuthContext);

    const logout = () => {
        Cookies.remove('access_token');
        setIsLoggedIn(false);
        navigate('/');
    };


    const getMatches = (date) => {
        setLoading(true); 
        request.post(`/matches/to-manage/${user_id}`, {
            date
        },{
            headers: {
                'Authorization': token
            }
        })
        .then((res => {
            setMatches(res.data.matches);
            setLoading(false); 
        }))
        .catch(e => {
            const { status, message } = e.response.data;
            if (status === 400) {
                handleOpenErrorModal(message)
            } else if (status === 401){
                logout()
            }
        });
    };

    useEffect(() => {
        getMatches(new Date());
    }, []);

    return (
        <UITemplate title="Oficjalne wyniki">
            <DateBar getMatches={getMatches} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
            {loading ? ( 
                <LoadingPanel />
            ) : (
                <div className='official-scores-list'>
                    <OfficialScoresPanel matches={matches} logout={logout} getMatches={getMatches} handleOpenModal={handleOpenModal} handleOpenErrorModal={handleOpenErrorModal} />
                </div>
            )}

        <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
            <img className="icon-modal" src={ImageError} alt="" />
        </SimpleModal>
        <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
            <img className="icon-modal" src={ImageAccept} alt="" />
        </SimpleModal>
        </UITemplate>
    );
};

export default RoomOfficialScoresPage;
