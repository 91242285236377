import React, { useState } from 'react';
import './CreateRoomPanel.css'
import CustomCreateInput from '../CustomCreateInput/CustomCreateInput';
import CustomCreateButton from '../CustomCreateButton/CustomCreateButton';
import CustomInput from '../CustomInput/CustomInput';
import { FaCartPlus } from "react-icons/fa";



const CreateRoomPanel = (props) => {
    const {handleOnSubmit,} = props;

    const [players, setPlayers] = useState(10);
    const [roomName, setRoomName] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");

    const [isButtonDisabled, setIsButtonDisabled] = useState(true); 

    const handlePlayers = (action) => {
        if (action === "plus") {
            setPlayers(players + 1);
        } else if (action === "minus" && players > 1) {
            setPlayers(players - 1);
        }
    };

    const handleOnChangeRoomName = (e) => {
        const inputValue = e.target.value;
        const isValidInput = /^[a-zA-Z0-9]*$/.test(inputValue);
        if (inputValue.length < 21 && isValidInput) {
            setRoomName(inputValue);
            setIsButtonDisabled(inputValue === "" ||  ownerEmail === "");
        }
    };

    const handleOnChangeOwnerEmail = (e) => {
        const inputValue = e.target.value;
        setOwnerEmail(inputValue);
        setIsButtonDisabled(inputValue === "" ||  roomName === "");

    };

    const handleOnClick = (e) => {
        if (handleOnSubmit(e, players, ownerEmail, roomName)){
            setPlayers(10);
            setRoomName("");
            setOwnerEmail("");
        }
    }

    return (
        <form className='create-room-panel' onSubmit={handleOnClick}>
            <FaCartPlus className='payment-icon'/>
            <span className='info'>Stwórz pokój, który najlepiej odpowiada Twoim preferencjom dotyczącym rozmiaru</span>
            <span className='title'>Wprowadź nazwę pokoju</span>
            <CustomCreateInput labelName={"Nazwa pokoju"} handleOnChange={handleOnChangeRoomName} valueInput={roomName} typeInput={"text"} placeholder={"Wymyśl nazwę (maks. 20 znaków)"}/>
            <span className='title'>Wprowadź właściciela pokoju</span>
            <CustomCreateInput labelName={"Email właściciela"} handleOnChange={handleOnChangeOwnerEmail} valueInput={ownerEmail} typeInput={"email"} placeholder={"Wprowadź email"}/>
            <span className='title'>Wybierz rozmiar pokoju</span>
            <CustomInput handlePlayers={handlePlayers} players={players}/>
            <CustomCreateButton text={"Utwórz"} action={handleOnClick} isDisabled={isButtonDisabled}/>
        </form>
    );
};

export default CreateRoomPanel;