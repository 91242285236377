import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import LoginPage from '../../pages/LoginPage/LoginPage.js';
import { AuthContext } from '../../helpers/AuthContext.js';
import UserPage from '../../pages/UserPage/UserPage.js';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage.js';


const Content = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = Cookies.get('access_token');
        setIsLoggedIn(!!token); 
    }, []);

    useEffect(() => {
        if (isLoggedIn && location.pathname === '/' ) {
          navigate('/user/scores');
        }
      }, [isLoggedIn, location.pathname, navigate]);


    const ProtectedRoute =  ({ setIsLoggedIn, element: Element, ...rest }) => {

        if (isLoggedIn) {
            
            return <Element {...rest} />;
        }
        else  return <LoginPage/>; 
      }
    
    return (
        <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='/user/*' element={<ProtectedRoute element={UserPage} />} />
            <Route path='*' element={<NotFoundPage/> }/>
        </Routes>        
    );
};

export default Content;