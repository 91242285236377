import React, { Children } from 'react';
import ReactModal from 'react-modal';
import './SimpleModal.css';

ReactModal.setAppElement('#root');

const SimpleModal = ({handleCloseModal, showModal, children}) => {

    return (
        <ReactModal 
           isOpen={showModal.status}
           onRequestClose={handleCloseModal}
           shouldCloseOnOverlayClick={false}
           className="simple-modal"
           overlayClassName="simple-overlay"
        >
            <div className='simple-icon-wrap'>
            {children}
            </div>
          <span className='simple-message-modal'>{showModal.message}</span>
          <div className="simple-modal-button" onClick={handleCloseModal}>OK</div>
        </ReactModal>
    );
};


export default SimpleModal;