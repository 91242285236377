import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import request from '../../helpers/request';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../helpers/AuthContext.js';

import './RoomListPage.css'
import UITemplate from '../../layouts/UITemplate/UITemplate';
import ListElement from './components/ListElement/ListElement';

import { IoIosAdd } from "react-icons/io";
import LoadingPanel from '../../components/LoadingPanel/LoadingPanel';

const RoomsListPage = () => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true); 
    const navigate = useNavigate();
    const getRooms = () => {    
        const token = Cookies.get('access_token');
        const decodedToken = jwtDecode(token);
        const id = decodedToken.id;
        request.get(`/rooms/${id}`,{
            headers: {
                'Authorization': token
            }})
        .then(res => {
            setRooms(res.data.rooms);
            setLoading(false); 
        })
        .catch(e => {
          const { code, message } = e.response.data;
          if (code === 400) {
            alert(message);
            setLoading(false); 
          } else if (code === 401) {
            logout();
          }
        });
    };
    const { setIsLoggedIn } = useContext(AuthContext);
    const logout = () => {
        Cookies.remove('access_token');
        setIsLoggedIn(false);
        navigate('/');
    };


    useEffect(() => {
        getRooms();
    }, []);

    const roomsList = rooms.length > 0 ? rooms.map((room) => <ListElement key={room.roomName} roomName={room.roomName} size={room.size} players={room.playersCount} status={room.status}/> ) : <div className='lack-of-rooms' >Brak pokoi</div>;

    return (
        <UITemplate title={"Pokoje"}>
            <Link className={"create-room"} to={`/user/create-room`}><IoIosAdd/></Link>
            <div className='number-of-rooms'>Znalezione pokoje: {rooms.length}</div>
            {loading ? <LoadingPanel /> : roomsList} 
        </UITemplate> 
    );
};

export default RoomsListPage;