import React, { useState } from 'react';
import './LoginContent.css';
import CustomLoginInput from '../../../CustomLoginInput/CustomLoginInput';
import CustomLoginButton from '../../../CustomLoginButton/CustomLoginButton';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LoginContent = (props) => {
    const {sendLoginRequest, loading} = props;

    const [username, setUsername] = useState('');
    const [password,setPassword] = useState('');
    
    const handleOnChangeUsername = (e) => {
        setUsername(e.target.value);
    }; 

    const handleOnChangePassword = (e) => {
        setPassword(e.target.value);
    };
    
    const loginForm = {
        username,
        password
    }
    return (
        <form className='login-content'>
            <span className='title-login'>Logowanie</span>
            <CustomLoginInput handleOnChange={handleOnChangeUsername} valueInput={username} labelName={"Login"} typeInput={"text"} placeholder={"Wprowadź login"}/>
            <CustomLoginInput handleOnChange={handleOnChangePassword} valueInput={password} labelName={"Hasło"} typeInput={"password"} placeholder={"Wprowadź hasło"}/>
            <CustomLoginButton action={(e)=> sendLoginRequest(e, loginForm)} text={"Zaloguj"}>
                {loading ? <AiOutlineLoading3Quarters className="loading-icon" /> : "Zaloguj"}
            </CustomLoginButton>
        </form>
    );
};

export default LoginContent;