import React, { useState } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import CreateRoomPanel from "./components/CreateRoomPanel/CreateRoomPanel";
import request from "../../helpers/request";
import UITemplate from '../../layouts/UITemplate/UITemplate';
import "./CreateRoomPage.css";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import ImageError from '../../icons/lock.png';
import { useNavigate } from "react-router-dom";
import ImageAccept from '../../icons/accept.png';


const CreateRoomPage = () => {
  const [token, setToken] = useState(Cookies.get('access_token'));
  const navigate = useNavigate();

  const [showModalError, setShowModalError] = useState({status: false, message: ""});
  const [showModal, setShowModal] = useState({status: false, message: ""});

  const handleOpenErrorModal = (text) => setShowModalError({status: true, message: text});
  const handleCloseErrorModal = () => setShowModalError({status: false});

  const handleOpenModal = (text) => setShowModal({status: true, message: text});  
  const handleCloseModal = () => setShowModal({status: false});

  const handleOnSubmit = (e, players, ownerEmail, roomName) => {
    e.preventDefault();

    if (token) {
          const decodedToken = jwtDecode(token);
          const user_id = decodedToken.id;
      if(request.post(`/rooms/create-room-by-admin/${user_id}`, {
          players,
          ownerEmail,
          roomName,
        },{
          headers: {
              'Authorization': token
          }})
        .then((res) => {
          handleOpenModal("Pomyślnie stworzono pokój.")
          return true
        })
        .catch((e) => {
          const { status, message } = e.response.data;
          if (status === 400) {
            handleOpenErrorModal(message);
            return false
          } else if (status === 401){
            navigate('/user/rooms');
        }
        })) return true
        else return
      }

  };

  return (
    <UITemplate title={"Stwórz pokój"}>
  
       <CreateRoomPanel 
        handleOnSubmit={handleOnSubmit}
      />

        <SimpleModal handleCloseModal={handleCloseErrorModal} showModal={showModalError}> 
            <img className="icon-modal" src={ImageError} alt="" />
        </SimpleModal>
        <SimpleModal handleCloseModal={handleCloseModal} showModal={showModal}> 
            <img className="icon-modal" src={ImageAccept} alt="" />
        </SimpleModal>

    </UITemplate>
   
  );
};

export default CreateRoomPage;
