import React from 'react';
import './AccountItem.css';
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";

const AccountItem = ({ id, username, role, active, submit, activateAccount, deactivateAccount }) => {
    return (
        <div className='account-item'>
            <span className='username'>
                <span className='label'>Nazwa</span>
                <span className='name'>{username}</span>
            </span>
            <span className='role'>
                <span className='label'>Uprawnienia</span>
                <span className='name'>{role}</span>
            </span>
            {role !== "main-admin" && (
                <span className='action'>
                    {active ? (
                        <IoMdRemoveCircleOutline onClick={() => submit("Czy na pewno chcesz dezaktywować konto?", () => deactivateAccount(id))} />
                    ) : (
                        <IoMdAddCircleOutline onClick={() => submit("Czy na pewno chcesz aktywować konto?", () => activateAccount(id))} />
                    )}
                </span>
            )}
        </div>
    );
};

export default AccountItem;
