import React, { useEffect, useState } from 'react';
import './ChangeMatchItem.css';
import { format } from 'date-fns';
import { MdOutlineModeEdit } from "react-icons/md";
import { IoMdClose } from "react-icons/io";




const ChangeMatchItem = (props) => {
    const {match, resultTeam1, resultTeam2, penaltyTeam1, penaltyTeam2, matchId, action  } = props;
    const {team1_code, team2_code, start} = match;
    const startDate = start && format(start, "HH:mm");

    const [isActive, setIsActive] = useState(false);

    const [initialScoreValues, setInitialScoreValues] = useState({ resultTeam1, resultTeam2 });
    const [editedScoreValues, setEditedScoreValues] = useState({ resultTeam1, resultTeam2 });

    const [initialPenaltyValues, setInitialPenaltyValues] = useState({ penaltyTeam1, penaltyTeam2 });
    const [editedPenaltyValues, setEditedPenaltyValues] = useState({ penaltyTeam1, penaltyTeam2 });


    useEffect(() => {
        if (!isActive) {
            setEditedScoreValues(initialScoreValues);
            setEditedPenaltyValues(initialPenaltyValues);

        }
    }, [isActive, initialScoreValues]);

    const handleToggleActive = () => {
        setIsActive(!isActive);
        if (!isActive) {
            setInitialScoreValues({ resultTeam1, resultTeam2 });
            setInitialPenaltyValues({ penaltyTeam1, penaltyTeam2 });
        }
    };

    const handleScoreChangeInternal = (team, value) => {
        setEditedScoreValues(prevState => ({
            ...prevState,
            [team]: value
        }));
    };


    const handlePenaltyChangeInternal = (team, value) => {
        setEditedPenaltyValues(prevState => ({
            ...prevState,
            [team]: value
        }));
    };

    const handleSend = () => {
        action("group", matchId, editedScoreValues.resultTeam1, editedScoreValues.resultTeam2); 
        setIsActive(false);
        setInitialScoreValues(prevState => ({
            ...prevState,
            resultTeam1: editedScoreValues.resultTeam1,
            resultTeam2: editedScoreValues.resultTeam2,
        }));
        setInitialPenaltyValues(prevState => ({
            ...prevState,
            penaltyTeam1: editedPenaltyValues.penaltyTeam1,
            penaltyTeam2: editedPenaltyValues.penaltyTeam2,
        }));
    }

    const handleSendPlayoff = () => {
        action("playoff", matchId, editedScoreValues.resultTeam1, editedScoreValues.resultTeam2, editedPenaltyValues.penaltyTeam1,editedPenaltyValues.penaltyTeam2); 
        setIsActive(false);
        setInitialScoreValues(prevState => ({
            ...prevState,
            resultTeam1: editedScoreValues.resultTeam1,
            resultTeam2: editedScoreValues.resultTeam2,
        }));
        setInitialPenaltyValues(prevState => ({
            ...prevState,
            penaltyTeam1: editedPenaltyValues.penaltyTeam1,
            penaltyTeam2: editedPenaltyValues.penaltyTeam2,
        }));
    }


    return (
        <div className={`change-match-item ${isActive ? 'active' : ''}`}>
            <span onClick={handleToggleActive}className='edit-button'>
                {!isActive ? <MdOutlineModeEdit/> : <IoMdClose/>}
            </span>
            <span className='top'>
                    <span className='time'>{startDate}</span>
            </span>

            <span className='middle'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team1_code}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team1_code}</span>
                    { isActive ? (
                    <select className="score" onChange={(e) => handleScoreChangeInternal('resultTeam1', e.target.value)} value={editedScoreValues.resultTeam1}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    ): <span className="score-inactive">{resultTeam1}</span>
                    }
                    <span className='separator'>:</span>
                    { isActive ? (
                    <select className="score" onChange={(e) => handleScoreChangeInternal('resultTeam2', e.target.value)} value={editedScoreValues.resultTeam2}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    ): <span className="score-inactive">{resultTeam2}</span>
                    }

                    <span className='country-name'>{team2_code}</span>
            
                <span className='flag'>
                <img src={`/Assets/images/flags/${team2_code}.svg`} alt="" />
            </span>
            </span>
            {isActive ? (
                <span className='bottom'>
                    {(match.type === "playoff") && (Number(editedScoreValues.resultTeam1) === Number(editedScoreValues.resultTeam2)) ? (
                    <span className='penalty'>
                    <select className="score" onChange={(e) => handlePenaltyChangeInternal('penaltyTeam1', e.target.value)} value={editedPenaltyValues.penaltyTeam1}>
                    <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                        <span className='title'>Rzuty karne</span>
                        <select className="score" onChange={(e) => handlePenaltyChangeInternal('penaltyTeam2', e.target.value)} value={editedPenaltyValues.penaltyTeam2}>
                        <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                    </span>
        )
        : null}
                {/* <span className='container'> 
                <select className="score" onChange={(e) => handleYellowCardsChangeInternal('yellowCardsTeam1', e.target.value)} value={editedYellowCardsValues.yellowCardsTeam1}>
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>

            <span className='title'>Żółte kartki</span>

            <select className="score" onChange={(e) => handleYellowCardsChangeInternal('yellowCardsTeam2', e.target.value)} value={editedYellowCardsValues.yellowCardsTeam2}>
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
        </span>
        <span className='container'>
        <select className="score" onChange={(e) => handleRedCardsChangeInternal('redCardsTeam1', e.target.value)} value={editedRedCardsValues.redCardsTeam1}>

                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            <span className='title'>Czerwone kartki</span>
            <select className="score" onChange={(e) => handleRedCardsChangeInternal('redCardsTeam2', e.target.value)} value={editedRedCardsValues.redCardsTeam2}>
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
        </span> */}

        {(match.type === "playoff") && (Number(editedScoreValues.resultTeam1) === Number(editedScoreValues.resultTeam2)) ? (
            <span onClick={handleSendPlayoff} className='action'>Zapisz</span>
        ): (<span onClick={handleSend} className='action'>Zapisz</span>)}

                </span>

            ): null}

        </div>
    );
};

export default ChangeMatchItem;