import React from 'react';
import { format } from 'date-fns';
import './InactiveMatchItem.css';




const InactiveMatchItem = ({match, resultTeam1, resultTeam2}) => {

    const {team1_code, team2_code, start} = match;

    const startDate = start && format(start, "HH:mm");
    
    return (
        <div className='inactive-match-item'>
            <span className='top'>
                    <span className='time'>{startDate}</span>
                </span>

            <span className='bottom'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team1_code}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team1_code}</span>
                    <span className='score'>{resultTeam1}</span>
                    <span className='separator'>:</span>
                    <span className='score'>{resultTeam2}</span>
                    <span className='country-name'>{team2_code}</span>
            
                <span className='flag'>
                    <img src={`/Assets/images/flags/${team2_code}.svg`} alt="" />
                </span>
            </span>

        </div>
    );
};

export default InactiveMatchItem;