import React from 'react';
import { Link } from 'react-router-dom';

import './ListElement.css';
import { IoArrowForwardCircle } from "react-icons/io5";

const ListElement = ({roomName, size, players, status}) => {
    return (

        <div className='list-element'>
        <span className='room-name'>
            <span className='label'>Nazwa pokoju</span>
            <span className='name'>{roomName}</span>
        </span>
        <span className='players'>
            <span className='label'>Gracze</span>
            <span className='name'>{players} / {size}</span>
        </span>
        <span className='status'>
            <span className='label'>Aktywny</span>
            <span className='name'>{status ? "Tak" : "Nie"}</span>
        </span>
        <Link to={roomName} className='action'><IoArrowForwardCircle /></Link>
    </div>

    )
};

export default ListElement;