import React from 'react';
import { format } from 'date-fns';
import './MatchItem.css';




const MatchItem = (props) => {
    const {match, resultTeam1, resultTeam2, penaltyTeam1, penaltyTeam2, matchId, handleScoreChange, handlePenaltyChange, action } = props;

    const {team1_code, team2_code, start} = match;

    const startDate = start && format(start, "HH:mm");
    
    return (
        <div className='match-item'>
            <span className='top'>
                    <span className='time'>{startDate}</span>
                </span>

            <span className='middle'>
            <span className='flag'>
                <img src={`/Assets/images/flags/${team1_code}.svg`} alt="" />
            </span>
                    <span className='country-name'>{team1_code}</span>
                    <select className="score" onChange={(e) => handleScoreChange(matchId, 'resultTeam1', e.target.value)} value={resultTeam1}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    <span className='separator'>:</span>
                    <select className="score" onChange={(e) => handleScoreChange(matchId, 'resultTeam2', e.target.value)} value={resultTeam2}>
                        <option value=""></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>

                    <span className='country-name'>{team2_code}</span>
            
                <span className='flag'>
                <img src={`/Assets/images/flags/${team2_code}.svg`} alt="" />
            </span>
            </span>

            <span className='bottom'>
            {(match.type === "playoff") && (resultTeam1 !== "" && resultTeam2 !== "" && resultTeam1 !== null && resultTeam2 !== null && Number(resultTeam1) === Number(resultTeam2)) ? (
            <span className='penalty'>
                    <select className="score" onChange={(e) => handlePenaltyChange(matchId,'penaltyTeam1', e.target.value)} value={penaltyTeam1}>
                    <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                        <span className='title'>Rzuty karne</span>
                        <select className="score" onChange={(e) => handlePenaltyChange(matchId, 'penaltyTeam2', e.target.value)} value={penaltyTeam2}>
                                <option value=""></option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                    </span>
            ) : null}

                {/* <span className='container'> 
                        <select className="score" onChange={(e) => handleYellowCardsChange(matchId, 'yellowCardsTeam1', e.target.value)} value={yellowCardsTeam1}>
                            <option value=""></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>

                    <span className='title'>Żółte kartki</span>

                    <select className="score" onChange={(e) => handleYellowCardsChange(matchId, 'yellowCardsTeam2', e.target.value)} value={yellowCardsTeam2}>
                            <option value=""></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                </span>
                <span className='container'>
                <select className="score" onChange={(e) => handleRedCardsChange(matchId, 'redCardsTeam1', e.target.value)} value={redCardsTeam1}>
                            <option value=""></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    <span className='title'>Czerwone kartki</span>
                    <select className="score" onChange={(e) => handleRedCardsChange(matchId, 'redCardsTeam2', e.target.value)} value={redCardsTeam2}>
                            <option value=""></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                </span> */}
                {(match.type === "playoff") && (resultTeam1 !== "" && resultTeam2 !== "" && resultTeam1 !== null && resultTeam2 !== null && Number(resultTeam1) === Number(resultTeam2)) ? 
                <span onClick={()=> action("playoff", matchId, resultTeam1, resultTeam2, penaltyTeam1, penaltyTeam2 )} className='action'>Dodaj</span>
                : 
                <span onClick={()=> action("group",matchId, resultTeam1, resultTeam2 )} className='action'>Dodaj</span>
            }
            </span>

        </div>
    );
};

export default MatchItem;