import React, { Children } from 'react';
import ReactModal from 'react-modal';
import './DecisionModal.css';

ReactModal.setAppElement('#root');

const DecisionModal = ({handleCloseModal, action, showModal, children}) => {

    return (
        <ReactModal 
           isOpen={showModal.status}
           onRequestClose={handleCloseModal}
           shouldCloseOnOverlayClick={false}
           className="decision-modal"
           overlayClassName="decision-overlay"
        >
            <div className='decision-icon-wrap'>
            {children}
            </div>
            
          <span className='decision-message-modal'>{showModal.message}</span>
          <div className="decision-modal-button" onClick={()=> {action(); handleCloseModal()}}>Akceptuj</div>
          <div className="decision-modal-button" onClick={handleCloseModal}>Anuluj</div>

        </ReactModal>
    );
};


export default DecisionModal;